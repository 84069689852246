<template>
  <div>
    <v-row justify="end">
      <template>
        <div class="my-2">
          <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.amount="{ item }">
        {{ Intl.NumberFormat().format(item.amount) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Subscription</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ຊື່ແພັກເກດ*"
                      required
                      v-model="addValue.name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      required
                      v-model="selectedItem"
                      :items="packageType"
                      item-text="name"
                      item-value="name"
                      label="ເລືອກໄລຍະເວລາ*"
                      :rules="typeRule"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="ລາຄາ*"
                      required
                      v-model="addValue.amount"
                      :rules="amountRule"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="ຈຳນວນຕຸກນ້ຳສູງສຸດ*"
                      required
                      v-model="addValue.bottle_amount"
                      :rules="bottleAmountRules"
                      type="number"
                      class="input-number"
                      @keyup.enter="Add"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalAdd()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="Add()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Subscription</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ຊື່ແພັກເກດ*"
                      required
                      v-model="editValue.name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      required
                      v-model="editValue.package_type"
                      :items="packageType"
                      item-text="name"
                      item-value="name"
                      label="ເລືອກໄລຍະເວລາ*"
                      :rules="typeRule"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="ລາຄາ*"
                      required
                      v-model="editValue.amount"
                      :rules="amountRule"
                      type="number"
                      class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="ຈຳນວນຕຸກນ້ຳສູງສຸດ*"
                      required
                      v-model="editValue.bottle_amount"
                      :rules="bottleAmountRules"
                      type="number"
                      class="input-number"
                      @keyup.enter="Update"
                    ></v-text-field>
                    <!-- <p class="errors">
                      {{ server_errors.price }}
                    </p> -->
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalEdit()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="Update()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
    <!-- Toast-->
  </div>
</template>

<script>
import manage from "@/mixins/package/manage_subscription";
export default {
  mixins: [manage],
};
</script>

<style>
@import "../../../public/scss/main.scss";
</style>
