
//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data(){
        return {
            loading: false,
            TableLoading:false,
            deleteId:'',
            server_errors: {
              condition: '',
              price:''
          },
          packageType:[
             {
               id:1,
               name:"week"
             },
             {
               id:2,
               name:"month"
             },
             {
              id:2,
              name:"year"
            }
           ],
           selectedItem:'',
            addValue:{},
            editValue:{},
            items:[],
            headers: [
                {
                  text: "Subscription name",
                  align: "start",
                  sortable: false,
                  value: "name",
                },
                { text: "ໄລຍະເວລາ", value: "package_type" },
                { text: "ລາຄາແພັກເກດ", value: "amount" },
                { text: "ຈຳນວນຕຸກນ້ຳ", value: "bottle_amount" },
                { text: "Actions", value: "actions", sortable: false },
              ],
              typeRule: [(v) => !!v || 'ເລືອກໄລຍະເວລາ',],
              nameRules: [
                v => !!v || 'ຕ້ອງລະບຸຊື່ແພັກເກດ',
                v => (v && v.length >= 2) || 'Name must be less than 2 characters',
              ],
              amountRule: [
                v => !!v || 'ຕ້ອງລະບຸລາຄາ',
              ],
               bottleAmountRules: [
                v => !!v || 'ຕ້ອງລະບຸຈຳນວນຕຸກຕໍ່ແພັກເກດ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
              ],

              toast: {
                value: true,
                color:'success',
                msg: 'Success'
              },
              toast_error: {
                value: true,
                color:'error',
                msg: 'Something when wrong!'
              }
        }
    },
    methods:{
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
          },
        Add(){
          if(this.$refs.form.validate() == true){
            this.Submit();
          }
        },
        Submit(){
          this.loading = true;
          this.addValue.package_type = this.selectedItem;
          this.$admin.post('/add-package-subscription', this.addValue)
              .then(res =>{
                  if(res.data.success == true){
                      setTimeout(()=>{
                          this.loading = false;
                          this.CloseModalAdd();
                          this.addValue={};
                          // this.getData();
                          this.reset();
                          this.$store.commit("Toast_State",this.toast);
                      },1000);
                  }
              }).catch(error=>{
                  this.loading = false;
                  this.$store.commit("Toast_State",this.toast_error);
                  this.getData();
                  if(error.response.status == 422){
                      var obj = error.response.data.errors;
                      for (let [key,addValue] of Object.entries(obj)){
                          this.server_errors[key] = addValue[0];
                      }
                  }
              });
        },
        CloseModalAdd() {
            this.branch={};
            this.reset();
            this.getData();
            this.$store.commit("modalAdd_State", false);
          },
        OpenModalEdit(item) {
            this.editValue = item;
            this.$store.commit("modalEdit_State", true);
          },

          Update(){
            if(this.$refs.form.validate() == true){
          this.loading = true;
          this.$admin.put('edit-package-subscription/'+this.editValue.id,this.editValue)
              .then(res =>{
                  if(res.data.success == true){
                      setTimeout(()=>{
                          this.loading = false;
                          this.CloseModalEdit();
                          this.editValue={};
                          this.getData();
                          this.reset();
                          this.$store.commit("Toast_State",this.toast);
                      },300);
                  }
              }).catch(() => {
                  this.loading = false;
                  this.$store.commit("Toast_State",this.toast_error);
                  this.getData();
                  // if(error.response.status == 422){
                  //     var obj = error.response.data.errors;
                  //     for (let [key,branch] of Object.entries(obj)){
                  //         this.server_errors[key] = branch[0];
                  //     }
                  // }
              }
            );
            }
          },

          CloseModalEdit() {
            this.editValue={};
            this.getData();
            this.reset();
            this.$store.commit("modalEdit_State", false);
          },

          getData(){
            this.TableLoading =true;
              this.$admin.get('list-package-subscription').then(res => {
                setTimeout(() => {
                    this.items = res.data.listPackages;
                    this.TableLoading =false;
                }, 100);
            }).catch(() => {
              this.TableLoading =false;
              this.$store.commit("Toast_State",this.toast_error);
            });
          },
          closeDelete() {
          this.$store.commit("modalDelete_State", false);
        },
          deleteItem(id) {
            this.deleteId = id;
            this.$store.commit("modalDelete_State", true);
          },
          deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('delete-package-subscription/'+ this.deleteId).then(res => {
                if(res.data.success == true){
                    setTimeout(() =>{
                        this.getData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State",this.toast);
                    },1000);
                }
            }).catch(()=>{
                this.getData();
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
                this.$store.commit("Toast_State",this.toast_error);
            })
          },
          reset() {
            this.$refs.form.reset();
          },
    },
    watch:{
      'addValue.condition':function(){
        this.server_errors.condition = '';
    },
    'addValue.price':function(){
      this.server_errors.price = '';
  },
      'editValue.condition':function(){
        this.server_errors.condition = '';
    },
    'editValue.price':function(){
      this.server_errors.price = '';
    },
    },
    created() {
        this.getData();
    },
}
